.RemoteVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
}
.RemoteIFrame {
    position: absolute;
    top: 0.5vmin;
    left: 0.5vmin;
    width: calc(100% - 1vmin);
    height: calc(100% - 1vmin);
    padding: 0.5vmin;
    z-index: 2;
}

.RemoteVideoFullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    z-index: 100;
    object-fit: contain;
    background-color: black;
}

.RemoteVideoWrapper {
    overflow: hidden;
    position: relative;
    container-type: inline-size;
    width: 100%;
    height: 100%;
    z-index: 2;
    /*box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));*/
}

.ColumnDivide {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex-basis: 10%;
    height: 2.5%;
    padding-top: 0.5vmin;
    padding-bottom: 0.5vmin;
    background: linear-gradient(to bottom, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    box-shadow: 0 calc(1px + 0.10vmin) calc(1px + 0.25vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.ColumnSpacer {
    display: none;
    flex-basis: 10%;
    align-self: center;
    height: 5%;
}

.ColumnDivide > div {
    align-self: center;
    width: 100%;
    flex-grow: 1;
}

.ColumnDivide.active > div {
}

.ShowStateWrapper.Blocked {
    background-image: radial-gradient(rgba(var(--On-Air-Color), 0.9), rgba(var(--On-Air-Color), 0.5));
}

.ShowStateWrapper.Blocked > div {
    filter: brightness(120%);
    font-size: 12vw;
    color: var(--On-Air-Color-Text);
    text-shadow: 2px 0px black,
        2px 0px black,
        0px 2px black,
        0px -2px black,
        2px 2px black,
        -2px 2px black,
        2px -2px black,
        -2px -2px black;
}

.ShowStateWrapper {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
    border: 1px solid rgba(var(--Border-Color), var(--Shadow-Alpha));
}

.ShowStateWrapper > div {
    align-self: center;
    font-size: 10vmin;
    text-align: center;
    font-weight: 500;
    color: transparent; 
    background-color: black;
    text-shadow: 0px 2px 2px rgba(200,200,200,0.15);
    background-clip: text;
}

.ShowStateWrapperDynamic {
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 3;
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    opacity: 1;
    transition: 300ms;
    background: rgba(var(--Shadow-Color), 0.5);
}


.ShowStateWrapperDynamic.Hidden {
    opacity: 0;
    top: 50%;
    height: 0px;
    background: transparent;
}

.ShowStateWrapperDynamic.Embed {
    opacity: 0;
    transition: 10s;
    background-color: rgba(0,0,0,0.5);
    animation: StateIndicator 3.0s linear;
}

.ShowStateWrapperDynamic.Embed:hover {
}

@keyframes StateIndicator {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ShowStateWrapperColumnOuter {
    overflow: hidden;
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex-basis: 15%;
    height: 40%;
    border-radius: 2%;
    border: 2px solid rgba(var(--Border-Color), var(--Shadow-Alpha));
    padding: calc(2px + 0.25vmin);
    background: linear-gradient(to bottom, rgb(var(--Primary-Color)), rgb(var(--Primary-Color-Light)));
    filter: brightness(40%);
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.ShowStateWrapperColumnOuter.active {
    display: flex;
    height: 50%;
    flex-grow: 1;
    background: linear-gradient(to bottom, rgb(var(--Secondary-Color)), rgb(var(--Secondary-Color-Light)));
    filter: brightness(100%);
}

@container (min-width: 768px)
{
    .ShowStateWrapperColumnOuter{
        display: flex;
    }
    .ShowStateWrapperColumnOuter.active {
        flex-grow: 0;
        flex-basis: 20%;
    }

    .ColumnDivide {
        display: flex;
    }

    .ColumnSpacer {
        display: block;
    }
}

.ShowStateWrapperColumnInner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    flex-grow: 1;
    width: 100%;
    border-radius: 2%;
}

.ShowStateWrapperColumnInner > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    color: var(--Primary-Color-Text);
    /*color: transparent;
    background: 
        url('../../../Assets/BackgroundNoise.png'),
        linear-gradient(to bottom, var(--very-light-background-color), var(--light-background-color));
    background-clip: text;*/
}

.ShowStateWrapperColumnInner.active > div {
    filter: brightness(110%);
    background: none;
    color: var(--Secondary-Color-Text);
}

.ShowStateWrapperColumnInner.dim > div {
    filter: brightness(110%);
    background: none;
    color: var(--Primary-Color-Text);
}

.ShowStateWrapperColumnInner > .Header {
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}

.ShowStateWrapperColumnInner > .Header > div:first-child {
    font-size: calc(1.0vw + 1.0vh + 8px);
    font-weight: 400;
}

.ShowStateWrapperColumnInner > .Header > div:last-child {
    font-size: calc(0.7vw + 0.7vh + 5px);
}


.ShowStateWrapperColumnInner > .Body {
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}

.ShowStateWrapperColumnInner > .Body > div {
    font-size: calc(0.5vw + 0.5vh + 5px);
}

.ShowStateWrapperColumnInner > .Body > .Completed {
    font-size: calc(0.5vw + 0.5vh + 5px);
}

.ShowStateWrapperColumnInner > .Body > .PositionIndicator {
    flex-basis: 60%;
    font-size: 9vmin;
    font-weight: 500;
}

@keyframes OnAirIndicator {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes OffAirIndicator {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ShowStateWrapper.OffAir {
    opacity: 0;
    background-image: radial-gradient(rgba(var(--Off-Air-Color), 0.4), rgba(var(--Off-Air-Color), 0.5));
    box-shadow: inset 0 0 100vw black;
    animation: OffAirIndicator 2.5s linear;
}

.ShowStateWrapper.OnAir {
    opacity: 0;
    background-image: radial-gradient(rgba(var(--On-Air-Color), 0.4), rgba(var(--On-Air-Color), 0.5));
    box-shadow: inset 0 0 100vw black;
    animation: OnAirIndicator 2.5s linear;
}

.ShowStateWrapper.OnAir > div, .ShowStateWrapper.OffAir > div {
    width: 100%;
    text-align: center;
    background-image: radial-gradient(rgba(50,50,50,0.4), rgba(30,30,30, 0.3), rgba(20,20,20,0), rgba(0,0,0,0));
    font-size: 20vmin;
    font-weight: 700;
    opacity: 0.9;
    text-shadow:
        5px 0px black,
        -5px 0px black,
        0px 5px black,
        0px -5px black,
        5px 5px black,
        -5px 5px black,
        5px -5px black,
        -5px -5px black;
}

.ShowStateWrapper.OffAir > div {
    color: rgb(var(--Off-Air-Color-Text));
}

.ShowStateWrapper.OnAir > div {
    color: rgb(var(--On-Air-Color-Text));
}


.CustomImageWrapper {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
}

.CustomImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
}