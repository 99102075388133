:root {

    --Light-Scalar:1.15;
    --Very-Light-Scalar:1.35;
    --Dark-Scalar:0.8;
    
    /* Primary Color */
    --Primary-Color-R:120;
    --Primary-Color-G:120;
    --Primary-Color-B:120;
    --Primary-Color: var(--Primary-Color-R), var(--Primary-Color-G), var(--Primary-Color-B);
    --Primary-Color-Dark: max(calc(var(--Primary-Color-R) * var(--Dark-Scalar)), 0), max(calc(var(--Primary-Color-G) * var(--Dark-Scalar)), 0), max(calc(var(--Primary-Color-B) * var(--Dark-Scalar)), 0);
    --Primary-Color-Light: min(calc(var(--Primary-Color-R) * var(--Light-Scalar)), 255), min(calc(var(--Primary-Color-G) * var(--Light-Scalar)), 255), min(calc(var(--Primary-Color-B) * var(--Light-Scalar)), 255);

    --Primary-Color-Text:rgb(238,238,238);

    /* Secondary Color */
    --Secondary-Color-R:12;
    --Secondary-Color-G:133;
    --Secondary-Color-B:250;
    --Secondary-Color: var(--Secondary-Color-R), var(--Secondary-Color-G), var(--Secondary-Color-B);
    --Secondary-Color-Dark: max(calc(var(--Secondary-Color-R) * var(--Dark-Scalar)), 0), max(calc(var(--Secondary-Color-G) * var(--Dark-Scalar)), 0), max(calc(var(--Secondary-Color-B) * var(--Dark-Scalar)), 0);
    --Secondary-Color-Light: min(calc(var(--Secondary-Color-R) * var(--Light-Scalar)), 255), min(calc(var(--Secondary-Color-G) * var(--Light-Scalar)), 255), min(calc(var(--Secondary-Color-B) * var(--Light-Scalar)), 255);
    
    --Secondary-Color-Text:rgb(238,238,238);
    
    /* Surface Color */ 
    /*--Surface-Color-R: 230;
    --Surface-Color-G: 230;
    --Surface-Color-B: 230;*/
    --Surface-Color-R:25;
    --Surface-Color-G:25;
    --Surface-Color-B:25;
    --Surface-Color: var(--Surface-Color-R), var(--Surface-Color-G), var(--Surface-Color-B);
    --Surface-Color-Dark: max(calc(var(--Surface-Color-R) * var(--Dark-Scalar)), 0), max(calc(var(--Surface-Color-G) * var(--Dark-Scalar)), 0), max(calc(var(--Surface-Color-B) * var(--Dark-Scalar)), 0);
    --Surface-Color-Light: min(calc(var(--Surface-Color-R) * var(--Light-Scalar)), 255), min(calc(var(--Surface-Color-G) * var(--Light-Scalar)), 255), min(calc(var(--Surface-Color-B) * var(--Light-Scalar)), 255);
    --Surface-Color-Very-Light: min(calc(var(--Surface-Color-R) * var(--Very-Light-Scalar)), 255), min(calc(var(--Surface-Color-G) * var(--Very-Light-Scalar)), 255), min(calc(var(--Surface-Color-B) * var(--Very-Light-Scalar)), 255);
    
    --Surface-Color-Text:240,240,240;
    /*--Surface-Color-Text: 68, 68, 68;*/
  
    /* Error Color */ 
    --Error-Color-R:250;
    --Error-Color-G:29;
    --Error-Color-B:15;
    
    --Error-Color: var(--Error-Color-R), var(--Error-Color-G), var(--Error-Color-B);
    --Error-Color-Dark: max(calc(var(--Error-Color-R) * var(--Dark-Scalar)), 0), max(calc(var(--Error-Color-G) * var(--Dark-Scalar)), 0), max(calc(var(--Error-Color-B) * var(--Dark-Scalar)), 0);
    --Error-Color-Light: min(calc(var(--Error-Color-R) * var(--Light-Scalar)), 255), min(calc(var(--Error-Color-G) * var(--Light-Scalar)), 255), min(calc(var(--Error-Color-B) * var(--Light-Scalar)), 255);
    
    /*--Surface-Color-Text: rgb(240, 240, 240);*/
    --Error-Color-Text:rgb(230,230,230);
    
    /* Affirmative Color */ 
    --Affirmative-Color-R:15;
    --Affirmative-Color-G:250;
    --Affirmative-Color-B:108;
    
    --Affirmative-Color: var(--Affirmative-Color-R), var(--Affirmative-Color-G), var(--Affirmative-Color-B);
    --Affirmative-Color-Dark: max(calc(var(--Affirmative-Color-R) * var(--Dark-Scalar)), 0), max(calc(var(--Affirmative-Color-G) * var(--Dark-Scalar)), 0), max(calc(var(--Affirmative-Color-B) * var(--Dark-Scalar)), 0);
    --Affirmative-Color-Light: min(calc(var(--Affirmative-Color-R) * var(--Light-Scalar)), 255), min(calc(var(--Affirmative-Color-G) * var(--Light-Scalar)), 255), min(calc(var(--Affirmative-Color-B) * var(--Light-Scalar)), 255);
    
    /*--Surface-Color-Text: rgb(240, 240, 240);*/
    --Affirmative-Color-Text:rgb(30,30,30);

    --Background-Color:rgb(15,15,15);
    /*--Background-Color: #aaaaaa;*/

    --Border-Color:90,90,90;

    --Alpha-Level:0.8;
    --Panel-Alpha-Level:0.8;

    --Shadow-Color:0,0,0;
    --Shadow-Alpha:0.4;
    --Border-Alpha:0.4;


    --Standby-Color:255,255,0;
    --Screening-Color:12,133,250;
    --Off-Air-Color:15,250,108;
    --On-Air-Color:250,29,15;

    --Off-Air-Color-Text: 255,255,255;
    --On-Air-Color-Text: 255,255,255;
}

* {
    font-family: 'Mada', sans-serif;
    box-sizing: border-box;
}

#root {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--Background-Color);
}

div {
    user-select: none;
}

body {
    margin: 0;
    background-color: black;
    overflow: hidden;
    /*min-height: 100vh;
    min-height: -webkit-fill-availble;
    max-height: 100vh;
    max-height: -webkit-fill-availble;
    height: 100vh;
    height: -webkit-fill-availble;
    min-width: 100vw;
    min-width: -webkit-fill-availble;
    max-width: 100vw;
    max-width: -webkit-fill-availble;
    width: 100vw;
    width: -webkit-fill-availble;*/
}

select {
    background: linear-gradient(to bottom right, rgb(var(--Primary-Color)), rgb(var(--Primary-Color-Light)));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.25vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    color: var(--Primary-Color-Text);
}

select:hover {
    filter: brightness(120%);
}

select:active {
    filter: brightness(90%);
}

select option {
    background: rgb(var(--Surface-Color));
    color: rgb(var(--Surface-Color-Text));
}

.Button.Primary {
    background: linear-gradient(to top left, rgb(var(--Primary-Color)), rgb(var(--Primary-Color-Light)));
    color: var(--Primary-Color-Text);
}
.Button.Secondary{
    background: linear-gradient(to top left, rgb(var(--Secondary-Color)), rgb(var(--Secondary-Color-Light)));
    color: var(--Secondary-Color-Text);
}

.Button.Inactive {
    filter: brightness(50%);
}

.Button.Error {
    background: linear-gradient(to top left, rgb(var(--Error-Color)), rgb(var(--Error-Color-Light)));
    color: var(--Error-Color-Text);
}

.Button.Affirmative{
    background: linear-gradient(to top left, rgb(var(--Affirmative-Color)), rgb(var(--Affirmative-Color-Light)));
    color: var(--Affirmative-Color-Text);
}

.Button.Surface {
    background: linear-gradient(to top left, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    color: rgb(var(--Surface-Color-Text));
}
.Button.Surface.Primary {
    color: var(--Primary-Color-Text);
}
.Button.Surface.Secondary {
    color: var(--Secondary-Color-Text);
}

.Button.Square {
    border-radius: 0 0;
}

.Button {
    background: linear-gradient(to top left, rgb(var(--Surface-Color)), rgb(var(--Surface-Color-Light)));
    border-top-right-radius:  0.25vmin;
    border-top-left-radius: 0.25vmin;
    border-bottom-right-radius: 0.25vmin;
    border-bottom-left-radius: 0.25vmin;
    /*border-top-right-radius:  5% 50%;
    border-top-left-radius: 5% 50%;
    border-bottom-right-radius: 5% 50%;
    border-bottom-left-radius: 5% 50%;*/
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    padding: 0.5em;
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(1px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    color: rgb(var(--Primary-Color));
    text-align: center;
}

.Button:hover {
    filter: brightness(120%);
}

.Button:active {
    filter: brightness(80%);
}

.Button.Inactive:hover {
    filter: brightness(50%);
}
.Button.Inactive:active{
    filter: brightness(50%);
}

.Spacer {
    flex-grow: 1;
}

#scroller {
    visibility: hidden;
    position: relative;
    z-index: 10;
    left: 0;
    width: 1px;
    height: 9999999999999999999999px;
}

html {
    height: 100vh;
    width: 100vw;
}

*::-webkit-scrollbar {
	width: calc(8px + 0.15vmin);
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-image: linear-gradient(to bottom right, rgb(var(--Secondary-Color), 0.8), rgb(var(--Secondary-Color-Light), 0.8));
	box-shadow: 0 2px calc(5px + 0.05vmin) calc(2px + 0.05vmin) var(--background-color-N3);
}
