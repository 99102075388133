.ConfiguratorWrapper {
    overflow-y: auto;
    width: 1vw;
    height: 100vh;
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    border-left: 1vw solid rgb(var(--Primary-Color));
    background: rgba(var(--Surface-Color), var(--Panel-Alpha-Level));
    transition: 300ms;
}

.ConfiguratorWrapper.Pinned, .ConfiguratorWrapper:hover {
    width: 30vw;
}

.InputWrapperOuterStyle {
    display: flex;
    flex-direction: column;
    color: rgb(var(--Surface-Color-Text));
    background: linear-gradient(to top right, rgb(var(--Surface-Color-Light)), rgb(var(--Surface-Color-Very-Light)));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
}

.InputWrapperOuterStyle select 
{
    padding: calc(5px + 0.45vmin);
    margin-bottom: calc(8px + 0.25vmin);
}

.InputWrapperInnerStyle, .ButtonWrapperStyle, .LinkWrapperStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: calc(5px + 0.25vmin);
}

.ButtonWrapperStyle > div {
    width: 45%;
}

.LinkWrapperStyle > .Button {
    width: 25%;
    margin: calc(10px + 0.45vmin);
    border-radius: 5px;
    align-self: center;
}

.InputWrapperHeaderStyle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.InputStyle {
  margin-left: calc(5px + 0.25vmin);
  margin-right: calc(5px + 0.25vmin); 
}

.ComponentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ToggleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ToggleButton {
    width: calc(8px + 1.5vmin);
    height: calc(8px + 1.5vmin);
    margin-left: calc(2px + 0.25vmin);
    margin-right: calc(5px + 0.25vmin); 
    background-color: transparent;
    border: 2px solid rgb(var(--Primary-Color));
}

.ToggleButton.Active {
    background-color: rgb(var(--Secondary-Color));
    filter: brightness(120%);
}

.ToggleButton:hover {
    filter: brightness(120%);
}

.ToggleButton:active {
    filter: brightness(80%);
}

.ColorChecker {
    width: calc(5px + 1.5vmin);
    height: calc(5px + 1.5vmin);
    border: 2px solid rgb(var(--Border-Color));
}

.Header {
    padding-right: 10px;
    font-size: calc(10px + 1.0vmin);
}